<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <div
      v-if="promos.length"
      class="accordion"
      role="tablist"
    >
      <draggable
        animation="200"
        ghost-class="ghost-sort"
        :list="promos"
        @change="changedSorting"
      >
        <div
          v-for="promo in promos"
          :key="promo.id"
          class="accordion__item "
        >
          <div class="accordion-label accordion-label--disable-arrow">
            <div class="accordion-label__content">
              <div class="row w-100 align-items-center">
                <div class="col-12 col-lg-6">
                  <div class="d-flex align-items-center">
                    <feather-icon
                      icon="MenuIcon"
                      class="mr-2"
                    />
                    <div class="d-flex align-items-center">
                      <div
                        v-if="promo.cover_image && promo.cover_image.url"
                        class="content-image-wrap"
                      >
                        <img
                          :src="promo.cover_image.url"
                          alt="cover"
                        >
                      </div>
                      <div class="content-text-wrap">
                        {{ promo.title }}<br>
                        <small>{{ promo.slug }}</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-2">
                  {{ promo.promo_ends_at | momentFormat }}
                </div>
                <div class="col-12 col-lg-2">
                  <b-form-checkbox
                    disabled
                    switch
                    :checked="promo.is_published"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </draggable>
    </div>
    <div v-else>
      Пока нет ни одной акции
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Draggable from 'vuedraggable'
import Promo from '@/api/http/models/promo/Promo'
import { BOverlay, BFormCheckbox } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BOverlay,
    BFormCheckbox,
    Draggable,
  },
  data() {
    return {
      showLoading: true,
      promos: [],
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const response = await Promo.getAllSort(this.currentSite.slug)

    if (response.data) {
      this.promos = response.data
      this.showLoading = false
    }
  },
  methods: {
    async changedSorting() {
      const promos = this.promos.map((promo, index) => ({
        id: promo.id,
        order: index + 1,
      }))

      const response = await Promo.sort(this.currentSite.slug, {
        promos,
      })

      if (response.status === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Акции отсортированы',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }
    },
  },
}
</script>
