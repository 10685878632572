import HTTPService from '@/api/http/HTTPService'
import URLSearchParams from '@/@core/utils/getUrlParams'
import PromoInterface from './PromoInterface'
import PromoSortInterface from './PromoSortInterface'

export default {
  getAll: (siteSlug: string, query?: {page?: number, search?: string, is_published?: number}) => {
    const queryString = query ? URLSearchParams(query) : false
    return HTTPService.get(`site/${siteSlug}/promo${queryString ? `?${queryString}` : ''}`)
  },
  getAllSort: (siteSlug: string) => HTTPService.get(`site/${siteSlug}/promo/all`),
  add: (siteSlug: string, payload: PromoInterface) => HTTPService.post(`site/${siteSlug}/promo`, payload),
  getOne: (siteSlug: string, promoPage: string) => HTTPService.get(`site/${siteSlug}/promo/${promoPage}`),
  update: (siteSlug: string, promoPage: string, payload: PromoInterface) => HTTPService.put(`site/${siteSlug}/promo/${promoPage}`, payload),
  activate: (siteSlug: string, promoPage: string, isPublished: boolean) => HTTPService.patch(`site/${siteSlug}/promo/${promoPage}`, {
    is_published: isPublished,
  }),
  delete: (siteSlug: string, promoPage: string) => HTTPService.delete(`site/${siteSlug}/promo/${promoPage}`),
  sort: (siteSlug: string, payload: PromoSortInterface) => HTTPService.post(`site/${siteSlug}/promo/reorder`, payload),
}
